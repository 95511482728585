<template>
  <v-container fluid fill-height class="pa-0 ma-0 narekomu-back" >
    <div class="login-back people"></div>
    <v-card id="login-card" class="elevation-1   login-card  body-2" >
      <v-toolbar
          color="blue-grey darken-4"
          dark
          flat
          height="80"
      >    
        <v-img 
          src="@/assets/logo.webp"
          max-width="170"
          class="mx-auto "
          asspect-rate
      ></v-img>
    </v-toolbar>
      <v-card-text class="mt-3 pb-1">
    
        <v-form ref="loginForm">
          <v-text-field
            outlined
            dense
            name="login"
            label="メールアドレス"
            type="text"
            v-model="model.email"
            :counter="255"
            :rules="emailRules"
            required
            class="my-1 "
            @keydown.enter="login"
          ></v-text-field>
          <v-text-field
            name="password"
            label="パスワード"
            :append-icon="showPassword ? svgEye : svgEyeOff"
            @click:append="showPassword = !showPassword"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            outlined
            dense
            v-model="model.password"
            :counter="32"
            :rules="passwordRules"
            required
            class="my-1 "
            @keydown.enter="login"
          ></v-text-field>
        </v-form>
          <v-alert v-if="isNoMatch"
            dense
            color="red accent-1"
            class="white--text caption"
          >
            メールアドレス,パスワードが正しくありません
          </v-alert>
          <v-alert v-else-if="isAccountLocked"
            dense
            color="red accent-1"
            class="white--text caption"
          >
            アカウントがロックされています。30分待つか、メールを確認して下さい。
          </v-alert>
      </v-card-text>
      <v-row justify="end">
        <v-btn 
          link 
          to="/reset_password"
          text
          dense
          id="no-background-hover"
          class="mb-2 mt-2 mr-3 primary--text text-center"
          >パスワードを忘れた方</v-btn>
        </v-row>
      <div class="login-btn px-4 pb-4">
        <v-btn block dark    color="blue-grey darken-4" @click="login" :loading="loading"> 
          <v-icon small >
            {{svgLockOpen}}
          </v-icon>
          サインイン
        </v-btn>
      </div>
      <div class="pa-4">
        <p style="border-top:1px solid gray  "></p>
        <p class="ma-0  pa-0 text-center text--secondary" >初めてご利用の方</p>
        <v-row justify="center"
          class="mr-2;"
        >
          <v-btn 
            link 
            to="/create_account"
            text
            dense
            id="no-background-hover"
            class="mb-4 mt-2 primary--text text-center"
            >アカウントを作成する</v-btn>
        </v-row>
      </div>
    </v-card>
    <!-- 通知 -->
    <v-snackbar
      v-model="isNotification"
      timeout="-1"
      top
      elevation="0"
      color="transparent"
      width="100%"
      max-width="1200px"
      class="ma-0 pa-0 "
      content-class="ma-0 pa-0  rounded "
      style="  z-index:2100; "
    >

      <div class="ma-0 mb-0" >       
        <v-alert
          width="100%"
          class="my-1 py-0 white--text"
          border="left"
          colored-border
          style="background:white"
          :type="targetNotification.type"
          elevation="2"
          prominent
        >
          <v-btn
            color="black"
            style="position:absolute ; right:10px; top:5px;  z-index:2110;"
            icon
            small
            dense
            @click="deleteNotification()"
          >
            <v-icon>{{svgClose}}</v-icon>
          </v-btn>
        <v-list-item class="ma-0 mb-0"   >
          <v-list-item-content class="black--text ma-0 mb-0" >
          <v-list-item-subtitle class="ma-0 mb-0 grey--text "  style="white-space:normal;">{{targetNotification.title}}</v-list-item-subtitle>
          <v-col v-html="targetNotification.text" class="ma-0 pa-1"></v-col>
          </v-list-item-content> 
        
        </v-list-item>
        </v-alert>

      </div>
  
    </v-snackbar>
  

   </v-container>
</template>

<script>
import Axios from "axios"
import { mdiClose, mdiEye,mdiEyeOff,mdiLockOpen } from '@mdi/js'
// import store from "../store"
export default {
    name: 'Login',
  data: () => ({

    svgClose:mdiClose,
    svgEye:mdiEye,
    svgEyeOff:mdiEyeOff,
    svgLockOpen:mdiLockOpen,
    loading: false,
    showPassword:false,
    isNoMatch:false,
    isAccountLocked:false,
    loadedList:[],
    completeList:['login', 'user'],
    types:["navi","object","image","pdf","sound","movie","url"],
    emailRules: [
      v => !!v || "メールアドレスは必須項目です。",
      v => (v && v.length <= 255) || "メールアドレスは255文字以内で入力してください。",
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "メールアドレスの形式が正しくありません。"
    ],
    passwordRules: [
      v => !!v || "パスワードは必須項目です。",
      v => (v && v.length <= 32) || "パスワードは32文字以内で入力してください。",
      v => (v && v.length >= 8 ) || "パスワードは8文字以上で入力してください。"
    ],
    model: {
      email: "",
      password: ""
    },
    systemNotification:[],
    targetNotification:{
      "id":"",
      "status":"",
      "type":"info",
      "title":"",
      "text":""
    },
    targetIndex:0,
    isNotification:false,
  }),

   head () {
    return {
      meta: [
        { description:'NarekomuVRはMR技術により空間ナビゲーションを行うことはできます。本ポータルサイトではコンテンツのアップロード、管理、確認が行えます。' }
      ]
    }
  },

  created(){
    this.getNotification();
  },

  mounted(){
      //labelと自動入力がかぶるのを避ける
      let labels = document.getElementsByTagName("label");
      labels[0].classList.add("v-label--active");
      labels[1].classList.add("v-label--active");
      labels[0].classList.add("back-white");
      labels[1].classList.add("back-white");
      setTimeout(function(){
         labels[0].style="background:white;left: 0px; right: auto; position: absolute;"
         labels[1].style="background:white;left: 0px; right: auto; position: absolute;"
      },300)
     
  },



  methods: {
    login () {  
      // バリデーションが通った場合
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        Axios({
            method: "POST",
            url:`${process.env.VUE_APP_URL}login`,
            data: JSON.stringify({ UserId:this.model.email, Password:this.model.password }),
            headers: {"X-Api-Key": process.env.VUE_APP_KEY,"Content-Type":"application/json"},
        }).then(res => {
          console.log(res)
            // 成功した場合
            if (res.data) {
                // ログイン情報を store に保存
                let login =  res.data["results"];
                this.$store.dispatch("setLoginInfo",login)
                .then(()=>{
                  this.loadedList.push("login");
                })
                this.$store.dispatch("setUserInfo",login )
                .then(()=>{
                  this.loadedList.push("user");
                })
                // 元の画面に戻る
                this.$router.push({path: "backuri" in this.$route.query && this.$route.query.backuri.match(/^\//) ? this.$route.query.backuri : '/'})

                
           
            } 
        }).catch((res) => {
           // メールアドレスとパスワードが正しくない組み合わせだった場合
          console.log(res.response.status)
          if(res.response.status == 429){
            this.isAccountLocked = true;
            this.isNoMatch = false;
          }else{
            this.isNoMatch = true;
            this.isAccountLocked = false;
          }
          this.loading = false;
          
          
        })
      }
    },

    getNotification(){
       Axios({
            method: "GET",
            url:`${process.env.VUE_APP_URL}notification`,
            params: {"document":"portalSettings"},
            headers: {"X-Api-Key": process.env.VUE_APP_KEY,"Content-Type":"application/json"},
            
        }).then(res => {
            let tmpData = res.data;
            if (tmpData.length){
              for(let i in tmpData){
              if(this.$store.state.user_model.read_id_list.indexOf(tmpData[i].id)==-1){
                this.systemNotification.push(tmpData[i]);
              }
            }
            this.targetNotification = this.systemNotification[0];
            if(this.systemNotification.length>0) this.isNotification = true;
            }
          
             
        }).catch((err) => {
           
          console.log(err)
      })
    },
    
    deleteNotification(){
      this.$store.dispatch("addReadList",this.targetNotification.id )
      this.targetIndex += 1;
      if(this.systemNotification.length > this.targetIndex){
        this.targetNotification =  this.systemNotification[this.targetIndex]
      }else{
        this.isNotification = false;
      }

    },
  }
}
</script>

<style  scoped>

    .snack__border{
      border:3px solid red;
      background:white;

    }
 
    @keyframes fadein {
      0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
    }


    #login-card {
        width:90%;
        max-width:350px;
        max-height:1000px;
        margin: auto;
        z-index:2000;

    }

    .narekomu-back{
        /* background: url("../assets/narekmu_vr_top2.png"); */
        background-size: cover;
        background-position: center;
        width: 100%;
        background-color: white;
    }

    
 

    #no-background-hover::before {
      background-color: transparent !important;
    }

    .back-white{
      background: white;;
    }

    .login-back{
      position:absolute;
       left: 0;
      right: 0;
      margin: auto;
      width:100%;
     
      height:100%;
    }

    .male{
      animation: fadein 0.4s ease-out forwards;
      position:absolute;
  
      right:0%;
      height:100%;
      max-width:850px;
      min-width:100px;
      
      z-index:1000;
      object-fit:cover;
      object-position:right top;
    }

    .female{
      animation: fadein 0.4s ease-out forwards;
      position:absolute;
      height:100%;
      left:0%;
      bottom:0;
      z-index:900;
      max-width:850px;
      min-width:100px;
      object-fit:cover;
      object-position:left top;
    }

    .people{
      animation: fadein 0.4s ease-out forwards;
      background: url("~@/assets/people.webp") no-repeat center center;
      background-size:auto 100%;
    }


    @media screen and (max-width: 1000px) {
      .male{
         position:absolute;
        height:100%;
        left:0;
        max-width:850px;
        min-width:100px;
        width:50%;
        bottom:0;
        z-index:1000;
        object-fit:cover;
        object-position:50% top;
      }

      .female{
        position:absolute;
        height:100%;
        left:50%;
        bottom:0;
       
        z-index:900;
        max-width:850px;
        min-width:100px;
        width:60%;
        object-fit:cover;
        object-position:50% top;
      } 

    }

</style>